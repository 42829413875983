<script setup lang="ts">
import { DeleteOutlined, EditOutlined } from '@ant-design/icons-vue'
import { cloneDeep } from 'lodash-es'
import { h } from 'vue'

const props = defineProps(['appItem', 'category'])
const emit = defineEmits(['confirm'])
const message = useMessage()
const cacheConfigStore = useWorkBenchDBConfigStore()
const open = defineModel<boolean>('open')
const title = ref()
const filesModalOpen = ref(false)
const appRef = ref()
const localApp = ref({ id: -1, name: '', icon: '', code: '', index: 1, category: '', categoryIndex: 1, url: '', authUrl: '' })
const codeOptions = ref([
  { label: '物联管理', value: 'iot' },
  { label: '安消一体化', value: 'intergration' },
  { label: '系统管理', value: 'systemManage' },
  // { label: '运维中心', value: 'eam' },
  { label: '内嵌', value: 'iframe' },
])

watchEffect(async () => {
  if (open.value === true) {
    localApp.value = cloneDeep(props.appItem) ?? { name: '', icon: '', index: 1 }
    title.value = props.appItem ? '修改' : '新增'
  }
})

function afterChooseIcon({ accessPath, _resourceId }: any) {
  localApp.value.icon = accessPath
}

async function handleOk() {
  appRef.value.validate().then(async () => {
    localApp.value.categoryId = props.category.id
    // 新增
    if (!props.appItem) {
      await cacheConfigStore.addApp(localApp.value)
    }
    else {
      await cacheConfigStore.updateApp(localApp.value)
    }

    message.success(`${title.value}成功`)
    open.value = false
    emit('confirm')
  })
}

function onChooseIcon() {
  filesModalOpen.value = true
}
function onDeleteIcon() {
  localApp.value.icon = ''
}
</script>

<template>
  <a-modal v-model:open="open" :title="title" width="50%" centered @ok="handleOk">
    <a-form v-if="localApp" ref="appRef" :model="localApp" :colon="false" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
      <a-form-item label="应用名称" name="name" required>
        <a-input v-model:value="localApp.name" />
      </a-form-item>
      <a-form-item label="应用图标" name="icon" required>
        <a-space>
          <a-image v-if="localApp.icon" :width="30" :height="30" :src="localApp.icon" :preview="false" />
          <a-button type="link" :icon="h(EditOutlined)" @click="onChooseIcon()" />
          <a-button v-if="localApp.icon" type="link" danger :icon="h(DeleteOutlined)" @click="onDeleteIcon()" />
        </a-space>
      </a-form-item>
      <a-form-item label="应用内部名称" name="code" required>
        <a-select v-model:value="localApp.code" :options="codeOptions" />
      </a-form-item>
      <a-form-item v-if="localApp.code === 'iframe'" label="应用链接" name="url">
        <a-input v-model:value="localApp.url" />
      </a-form-item>
      <a-form-item v-if="localApp.code === 'iframe'" label="应用权限链接" name="authUrl">
        <a-input v-model:value="localApp.authUrl" />
      </a-form-item>
      <a-form-item label="排序值" name="index" required>
        <a-input-number v-model:value="localApp.index" :min="1" />
      </a-form-item>
    </a-form>
  </a-modal>
  <FileChoose v-model:open="filesModalOpen" @on-choose-file="afterChooseIcon" />
</template>
