<script setup lang="ts">
import { cloneDeep } from 'lodash-es'

const props = defineProps(['category', 'operation'])
const cacheConfigStore = useWorkBenchDBConfigStore()
const open = defineModel<boolean>('open')
const title = ref()
const appRef = ref()
const localCategory = ref({ name: '', index: 1 })

function handleOk() {
  appRef.value.validate().then(async () => {
    if (props.operation === 'update') {
      await cacheConfigStore.updateCategory(cloneDeep(localCategory.value))
    }
    else {
      await cacheConfigStore.addCategory(localCategory.value)
    }
    open.value = false
  })
}

watch(open, (newOpen) => {
  if (newOpen === true) {
    if (props.operation === 'update') {
      title.value = '修改分类'
      localCategory.value = cloneDeep(props.category)
    }
    else {
      localCategory.value = { name: '', index: 1 }
      title.value = '新增分类'
    }
  }
})
</script>

<template>
  <a-modal v-model:open="open" :title="title" width="40%" centered @ok="handleOk">
    <a-form v-if="localCategory" ref="appRef" :model="localCategory" :colon="false" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
      <a-form-item label="分类名称" name="name" required>
        <a-input v-model:value="localCategory.name" />
      </a-form-item>

      <a-form-item label="排序值" name="index" required>
        <a-input-number v-model:value="localCategory.index" :min="1" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
