<script setup lang="ts">
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Button, Image, Popconfirm, Space } from 'ant-design-vue'
import UpdateCategory from './components/update-category.vue'
import Update from './components/update.vue'
import { InteriorAppNameMap } from '@/constant/config'

const dbConfigStore = useWorkBenchDBConfigStore()

const { apps, categories } = storeToRefs(dbConfigStore)

const updateModalVisible = ref(false)
const choosedApp = ref()
const categoryId = ref()
const updateCategoryVisible = ref(false)
const categoryOperation = ref('')

onMounted(async () => {
  await dbConfigStore.refreshDBConfig()
})

const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '应用名称', dataIndex: 'name' },
    {
      title: '应用图标',
      dataIndex: 'icon',
      customRender({ record }) {
        return record.icon ? h(Image, { preview: false, src: record.icon, width: 40, height: 40 }) : ''
      },
    },
    {
      title: '应用内部名称',
      dataIndex: 'code',
      customRender({ record }) {
        return `${InteriorAppNameMap.get(record.code) ?? ''} (${record.code})`
      },
    },
    { title: '应用链接', dataIndex: 'url' },
    { title: '应用权限链接', dataIndex: 'authUrl' },
    { title: '排序值', dataIndex: 'index' },
    {
      title: '操作',
      customRender({ record }) {
        return h(Space, { direction: 'horizontal', wrap: false }, () => [
          h(Button, {
            type: 'link',
            size: 'small',
            onClick: () => {
              updateModalVisible.value = true
              choosedApp.value = record
            },
          }, () => '修改'),
          h(
            Popconfirm,
            {
              title: '确定删除？',
              onConfirm: async () => {
                await dbConfigStore.deleteApp(record.id)
              },
            },
            () => h(
              Button,
              { danger: true, type: 'link', size: 'small' },
              () => '删除',
            ),
          ),
        ])
      },
    },
  ],
  config: {
    total: 0,
    loading: false,
    isBorder: false,
    isSerialNo: true,
    isSelection: true,
    isOperate: true,
  },
  pagination: false,
})

const selectedCategory = computed(() => {
  return categories.value.find(item => item.id === categoryId.value)
})

function onAddApp() {
  updateModalVisible.value = true
  choosedApp.value = undefined
}

function onSelectCategory(e: any) {
  categoryId.value = e[0]
}

watchEffect(() => {
  state.data = apps.value.filter(app => app.categoryId === categoryId.value)
})

function onAddCategory() {
  categoryOperation.value = 'add'
  updateCategoryVisible.value = true
}

function onUpdateCategory(id: number) {
  categoryId.value = id
  categoryOperation.value = 'update'
  updateCategoryVisible.value = true
}

function onDeleteCategory(id: number) {
  dbConfigStore.deleteCategory(id)
}
</script>

<template>
  <page-container>
    <content-header title="应用配置" />
    <flex-content :need-padding="false">
      <div h-full flex>
        <div flex-none w-200px style="border-right: 1px solid rgb(230, 230, 230);background: var(--bg-color);">
          <div p-12px flex justify-between items-center style="border-bottom: 1px solid rgb(230, 230, 230);">
            应用分类
            <PlusOutlined cursor-pointer @click="onAddCategory" />
          </div>
          <a-directory-tree
            block-node :default-expanded-keys="['0-0-0']" :tree-data="categories"
            :field-names="{ title: 'name', key: 'id' }" @select="onSelectCategory"
          >
            <template #icon />
            <template #title="slotData">
              <div flex justify-between items-center>
                {{ slotData.name }}
                <a-space>
                  <a-button size="small" type="link" @click="onUpdateCategory(slotData.id)">
                    <template #icon>
                      <EditOutlined />
                    </template>
                  </a-button>
                  <a-popconfirm
                    title="确定要删除该分类？" ok-text="确定" cancel-text="取消"
                    @confirm="onDeleteCategory(slotData.id)"
                  >
                    <a-button size="small" type="link" danger>
                      <template #icon>
                        <DeleteOutlined />
                      </template>
                    </a-button>
                  </a-popconfirm>
                </a-space>
              </div>
            </template>
          </a-directory-tree>
        </div>
        <div flex-1 flex flex-col overflow-hidden>
          <div
            style="border-bottom: 1px solid rgb(230, 230, 230);background: var(--bg-color);" px-12px py-10px
            text-14px font-600 flex-none
          >
            {{ selectedCategory?.name ?? '分类名称' }}
          </div>
          <block-content>
            <table-layout v-if="categoryId">
              <template #headerLeft>
                <a-space>
                  <a-button type="primary" @click="onAddApp">
                    新增应用
                  </a-button>
                </a-space>
              </template>
              <template #content>
                <base-table v-bind="state" />
              </template>
            </table-layout>
          </block-content>
        </div>
      </div>
    </flex-content>
    <Update v-model:open="updateModalVisible" :app-item="choosedApp" :category="selectedCategory" />
    <UpdateCategory v-model:open="updateCategoryVisible" :operation="categoryOperation" :category="selectedCategory" />
  </page-container>
</template>

<style lang="scss" scoped>
:deep(.ant-tree.ant-tree-directory) {

  .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
    color: #1677ff;
  }

  .ant-tree-treenode-selected::before {
    background-color: rgb(200 200 200);
  }
}
</style>
